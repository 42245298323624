import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import $ from "jquery";
import Swal from "sweetalert2";
import "datatables.net-bs5";

const ListAlumnos = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [alumnos, setAlumnos] = useState([]);
  const tableRef = useRef(null);

  useEffect(() => {
    const fetchData = async () => {
      // Verificar el rol del usuario
      const userRole = sessionStorage.getItem("role");
      if (userRole === "Docente") {
        try {
          const response = await axios.get(
            `${window.BACKEND_URL}docente/lista/alumnos/vista/${id}`,
            {
              headers: {
                Authorization: `${sessionStorage.getItem("token")}`,
                "Content-Type": "application/json",
                "X-Rol": userRole,
              },
            }
          );
          console.log(response.data);
          setAlumnos(response.data);
        } catch (error) {
          console.error("Error fetching alumnos:", error);
        } finally {
          setLoading(false);
        }
      } else {
        // Mostrar un mensaje de error o redirigir a otra página
        Swal.fire({
          title: "Error 404",
          text: "Recurso no encontrado",
          icon: "error",
          showConfirmButton: false, // No mostrar el botón de confirmación
          allowOutsideClick: false, // No permitir cerrar haciendo clic fuera del modal
        });
        setLoading(false); // Asegúrate de detener la carga en caso de error
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (!loading && tableRef.current) {
      const dataTable = $(tableRef.current).DataTable({
        searching: true,
        paging: true,
        lengthChange: true,
        pageLength: 50, // Establecer el número de registros por defecto a 50

        language: {
          search: "Buscar:",
          zeroRecords: "No se encontraron resultados",
          info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
          infoEmpty: "Mostrando 0 a 0 de 0 registros",
          infoFiltered: "(filtrado de _MAX_ registros totales)",
          paginate: {
            first: "Primero",
            last: "Último",
            next: "Siguiente",
            previous: "Anterior",
          },
          lengthMenu: " _MENU_ Alumnos",
        },
      });

      return () => {
        // Destruir la tabla de DataTables al desmontar el componente para evitar problemas de memoria
        dataTable.destroy();
      };
    }
  }, [loading, id]);

  return (
    <>
      {loading ? (
        <div className="text-center mt-5">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando Alumnos...</p>
        </div>
      ) : (
        <div className="table-responsive  ">
          <table className="table table-light rounded" ref={tableRef}>
            <thead className="table-secondary bg-secondary bg-opacity-10 shadow">
              <tr>
                <th>N°</th>
                <th>Nombre</th>
                <th>Roles</th>
                <th>Materia</th>
              </tr>
            </thead>
            <tbody>
              {alumnos.map((alumno, index) => (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{alumno.name.toUpperCase()}</td>
                  <td>
                    {alumno.roles.map((role) => role.toUpperCase()).join(", ")}
                  </td>
                  <td>
                    {alumno.materias
                      .map((materia) => materia.toUpperCase())
                      .join(", ")}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default ListAlumnos;
