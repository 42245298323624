import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { registerLocale } from "react-datepicker";
import es from "date-fns/locale/es"; // Importa la localización en español

registerLocale("es", es); // Registra la localización en español

function CuaderAddNotifi() {
  const navigate = useNavigate();
  const { id } = useParams();
  const { cuader_id } = useParams();
  const [username] = useState(sessionStorage.getItem("userName"));
  const [_id] = useState(sessionStorage.getItem("_id"));
  const [photo] = useState(sessionStorage.getItem("photo"));
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [expediente, setExpediente] = useState([]);
  const [ExpedienteCuaderno, setExpedienteCuaderno] = useState([]);
  const [titulocuso, setTituloCurdo] = useState([]);

  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const response123 = await axios.get(
          `${window.BACKEND_URL}alumno/expe_cuaderno/notifi/getid/${cuader_id}`,
          {
            params: { _id: id },
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        setExpedienteCuaderno(response123.data);

        const response = await axios.get(
          `${window.BACKEND_URL}alumno/expediente/num_expediente/get/${id}`,
          {
            params: { _id: id },
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        const response2 = await axios.get(
          `${window.BACKEND_URL}alumno/titulo/get/${response.data[0].datacurso[0].titulo_id}`,
          {
            params: { _id: id },
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        // Actualizar el estado de los grupos con los nombres de los usuarios
        setTituloCurdo(response2.data.nombre);
        console.log(response.data);
        setExpediente(response.data);
      } catch (error) {
        console.error("Error fetching :", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);
  const handleCancel = () => {
    window.history.back();
  };
  const confirmsFormu = async (e) => {
    e.preventDefault();
    if (submitting) {
      return;
    }

    setSubmitting(true); // Marcar el formulario como enviado

    Swal.fire({
      title: "Cargando",
      text: "Subiendo archivos...",
      timer: 2000,
      timerProgressBar: true,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    // Obtener el formulario utilizando su ID
    const form = document.getElementById("myForm");
    // Verificar si el formulario se encontró
    if (!form) {
      console.error("No se encontró el formulario con el ID proporcionado");
      return;
    }

    // Crear un objeto para almacenar los datos del formulario
    const dataform = {};

    // Recorrer todos los elementos del formulario
    for (let element of form.elements) {
      // Verificar si el elemento tiene un nombre y no es un botón
      if (element.name && element.type !== "submit") {
        // Agregar el valor del elemento al objeto formData
        dataform[element.name] = element.value;
      }
    }

    const notifi = new FormData();

    notifi.append("file", e.target.file.files[0]);
    notifi.append("observaciones", e.target.observaciones.value);
    notifi.append("tipo", "Notificación");
    notifi.append("sumilla", e.target.sumilla.value);
    notifi.append("user", username);
    notifi.append("alum_id", _id);
    if (dataform.grab_audiencia) {
      // Llamar a append solo si hay datos en dataform.grab_audiencia
      notifi.append("grab_audiencia", dataform.grab_audiencia);
    }

    const Anuncio = {};

    Anuncio.alumno_id = _id;
    Anuncio.numexpe = ExpedienteCuaderno.nexpediente;

    const audiencia = {};

    audiencia.user = Anuncio.user;
    audiencia.curso = titulocuso;
    audiencia.user = dataform.user;
    audiencia.acto = "Audicenica";
    audiencia.tipo_audi = dataform.tipo_audi;
    audiencia.fechaHoraInicio = startDate ? startDate.toISOString() : null;
    audiencia.fechaHoraFin = endDate ? endDate.toISOString() : null;

    audiencia.link_audi = dataform.link_audi;

    try {
      if (audiencia.link_audi) {
        notifi.append("estado", 2);
        notifi.append("acto", "Audencia");
        Anuncio.detalles = ` Agrego un notificacion y audiencia  `;

        await axios.put(
          `${window.BACKEND_URL}alumno/grupocurso/notifica/put/${expediente[0].grupo_id[0]._id}`,
          Anuncio,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );
        await axios.put(
          `${window.BACKEND_URL}alumno/expe_cuaderno/notifi/put/${cuader_id}`,
          notifi,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        await axios.put(
          `${window.BACKEND_URL}alumno/curso/audiencia/put/${expediente[0].curso_id}`,
          audiencia,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        Swal.fire({
          icon: "success",
          title: "Guardado",
          text: "¡Se guardó la notificacion y la audicencia correctamente!",
        });
        navigate(`/curso-alumno/${expediente[0].curso_id}`);
      } else if (e.target.file.files[0]) {
        notifi.append("estado", 1);
        notifi.append("acto", "Notificación");
        Anuncio.detalles = `Agrego un notificacion al cuaderno`;

        await axios.put(
          `${window.BACKEND_URL}alumno/grupocurso/notifica/put/${expediente[0].grupo_id[0]._id}`,
          Anuncio,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        await axios.put(
          `${window.BACKEND_URL}alumno/expe_cuaderno/notifi/put/${cuader_id}`,
          notifi,
          {
            headers: {
              Authorization: `${sessionStorage.getItem("token")}`,
            },
          }
        );

        Swal.fire({
          icon: "success",
          title: "Guardado",
          text: "¡Se guardó la notificacion correctamente!",
        });
        navigate(`/curso-alumno/${expediente[0].curso_id}`);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setSubmitting(false); // Marcar el formulario como no enviado
    }
  };

  const [mostrarFormulariOne, setMostrarFormularioOne] = useState(false);

  const toggleFormularioOne = () => {
    setMostrarFormularioOne(!mostrarFormulariOne);
  };

  const [mostrarFormulariTwo, setMostrarFormularioTwo] = useState(false);

  const toggleFormulariTwo = () => {
    setMostrarFormularioTwo(!mostrarFormulariTwo);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const fileType = file.type;
      if (fileType !== "application/pdf") {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "¡Por favor, selecciona un archivo PDF!",
        });
        event.target.value = ""; // Limpia la selección del archivo
      }
    }
  };

  return (
    <>
      <div className="container-fluid rounded-end-4 position-relative m-0 p-0">
        <div className="container-fluid mt-3 py-3">
          <strong class=" px-3 pt-4">Agregar Notificaciones</strong>
        </div>
        {loading ? (
          <p>Cargando...</p>
        ) : (
          <section>
            <div className="container-fluid mt-3">
              <form
                className="row g-3 needs-validation"
                id="myForm"
                onSubmit={confirmsFormu}
              >
                <div className="row">
                  <div className="col-md-8 px-4">
                    <div className="row">
                      <div className="col-md-4 mt-2">
                        <label htmlFor="inputEmail3">
                          <p>
                            N° de Expediente
                            <text className="text-danger">*</text>
                          </p>
                        </label>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          value={ExpedienteCuaderno.nexpediente}
                          disabled
                        />
                        <div className="invalid-feedback">
                          Campo Obligatorio.
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-4 mt-2">
                        <label htmlFor="inputEmail3">
                          <p>
                            Sumilla<text className="text-danger">*</text>
                          </p>
                        </label>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          name="sumilla"
                          required
                          placeholder="Ingrese un sumilla..."
                        />
                        <div className="invalid-feedback">
                          Campo Obligatorio.
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-4">
                        <label htmlFor="inputEmail3">
                          <p>Observaciones</p>
                        </label>
                      </div>
                      <div className="col-md-8 p-0 mt-2">
                        <textarea
                          className="form-control"
                          name="observaciones"
                          style={{
                            border: "1px solid rgb(172, 172, 172)",
                            height: "100px",
                            fontSize: "14px",
                            color: "#4b4b4b",
                          }}
                        ></textarea>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-md-5">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                          style={{ fontSize: "14px", paddingRight: "20px" }}
                        >
                          Programar Audiencia
                        </label>
                        <a
                          id="myButton"
                          className="btn"
                          onClick={toggleFormularioOne}
                        >
                          {mostrarFormulariOne}
                        </a>
                      </div>
                      <div className="col-md-7 px-2 ">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                          style={{ fontSize: "14px", paddingRight: "20px" }}
                        >
                          Link de audiencia grabada
                        </label>
                        <a
                          id="myButton"
                          className="btn"
                          onClick={toggleFormulariTwo}
                        >
                          {mostrarFormulariTwo}
                        </a>
                      </div>
                    </div>

                    <div className="row py-2">
                      {mostrarFormulariOne && (
                        <div className="">
                          <div className="row">
                            <div className="col-md-4 mt-2">
                              <label htmlFor="inputEmail3">
                                <p>
                                  Tipo de Audencia
                                  <text className="text-danger">*</text>
                                </p>
                              </label>
                            </div>
                            <div className="col-md-8 p-0 mt-2">
                              <select
                                class="form-select"
                                aria-label="Default select example"
                                name="tipo_audi"
                              >
                                <option selected>...</option>
                                <option value="Resolucion">Resolucion</option>
                                <option value="Notificación">
                                  Notificación
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4 mt-2">
                              <p>
                                Fecha/Hora de Inicio
                                <text className="text-danger">*</text>
                              </p>
                            </div>
                            <div className="col-md-8 p-0 mt-2 mb-0">
                              <DatePicker
                                showTimeSelect
                                selected={startDate}
                                onChange={(date) => {
                                  setStartDate(date);
                                }}
                                placeholderText="Seleccione una fecha"
                                dateFormat="Pp"
                                className="form-control border border-1 rounded text-center px-5 "
                                wrapperClassName="datepicker-wrapper"
                                locale="es"
                                timeCaption="Hora"
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-4 mt-2">
                              <p>
                                Fecha/Hora de Fin
                                <text className="text-danger">*</text>
                              </p>
                            </div>
                            <div className="col-md-8 p-0 mt-2 ">
                              <DatePicker
                                showTimeSelect
                                selected={endDate}
                                onChange={(date) => {
                                  setEndDate(date);
                                }}
                                placeholderText="Seleccione una fecha"
                                dateFormat="Pp"
                                className="form-control border border-1 rounded text-center px-5"
                                wrapperClassName="datepicker-wrapper"
                                locale="es"
                                timeCaption="Hora"
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-6 col-md-4  mt-2">
                              <p>
                                Link de Audiencia
                                <text className="text-danger">*</text>
                              </p>
                            </div>
                            <div className="col-md-8 p-0 mt-2 ">
                              <input
                                type="link"
                                className="form-control"
                                id="validationCustom01"
                                name="link_audi"
                                required
                                placeholder="http//linkdeejemplo.com"
                              />
                              <div className="invalid-feedback">
                                Campo Obligatorio
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row mt-3 mb-3">
                      <div className="col-md-12 px-2">
                        <div className="input-group">
                          <input
                            accept="application/pdf"
                            type="file"
                            className="form-control "
                            id="inputGroupFile02"
                            name="file"
                            onChange={handleFileChange}
                            required
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-3 mb-3">
                      {mostrarFormulariTwo && (
                        <div className="col-md-12 px-2">
                          <div className="input-group">
                            <span className="input-group-text">
                              Grabacion de audencia:
                            </span>
                            <input
                              name="grab_audiencia"
                              type="link"
                              className="form-control"
                              placeholder="http//linkdeejemplo.com"
                              aria-label="Ingrese el enlace"
                              aria-describedby="basic-addon2"
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="row">
                      <div className="col-6 p-0 mb-3 mt-4">
                        <a
                          type="button"
                          className="btn px-5"
                          style={{
                            backgroundColor: "#ffffff",
                            color: "#868686",
                            borderColor: "#868686",
                            textAlign: "right",
                            fontWeight: "500",
                          }}
                          onClick={handleCancel}
                        >
                          Cancelar
                        </a>
                      </div>
                      <div className="col-6 mb-4 mt-4 d-flex flex-row-reverse ">
                        <button
                          id="myButton"
                          className="btn btn-primary btn px-5"
                          type="submit"
                          disabled={submitting}
                        >
                          {submitting ? "Enviando..." : "Grabar"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>
        )}
      </div>
    </>
  );
}

export default CuaderAddNotifi;
