import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function DatosCurso() {
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      padding: "35px",
      maxWidth: "500px",
      width: "90%",
    },
  };
  const style1 = {
    width: "250px",
    color: "#e4e4e4",
    backgroundColor: "#009688",
  };
  const [cuadernos, setCuaderno] = useState([]);
  const [expediente, setExpediente] = useState([]);
  const [numexpe, setnumexpe] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modalIsOpen, setModalIsOpen] = useState(false);

  const [modalIdentifier, setModalIdentifier] = useState(null);

  const [cuadernoSeleccionado, setCuadernoSeleccionado] = useState("");

  const { id } = useParams();
  const [grupos, setGrupos] = useState([]);
  const [data_id] = useState(sessionStorage.getItem("_id"));

  const fetchData = async () => {
    try {
      setLoading(true);
      //cursos
      const response = await axios.get(
        `${window.BACKEND_URL}alumno/grupocurso/datos_curso/get/${data_id}`,
        {
          params: { curso_id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      console.log("datos cursos", response.data);
      const filteredGrupos = response.data.filter(
        (grupo) => grupo.curso_id === id
      );
      let alumnoIdsSet = new Set();
      response.data.forEach((data) => {
        data.alumnose.forEach((alumno) => {
          alumnoIdsSet.add(alumno.alumno_id);
        });
      });
      let alumnoIds = Array.from(alumnoIdsSet);
      // user
      const users = await axios.get(
        `${window.BACKEND_URL}alumno/users/get/ids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            userIds: alumnoIds,
          },
        }
      );
      console.log("datos user", users.data);

      const updatedGruposPromises = filteredGrupos.map(async (grupo) => {
        const alumnosWithNamesPromises = grupo.alumnose.map(async (alumno) => {
          const usuario = users.data.find(
            (data) => data._id === alumno.alumno_id
          );

          if (usuario) {
            alumno.nombre = usuario.name;
            alumno.photo = usuario.photo;
          } else {
            console.error(
              "Usuario no encontrado para el ID:",
              alumno.alumno_id
            );
          }
          return alumno;
        });

        const alumnosWithNames = await Promise.all(alumnosWithNamesPromises);

        return { ...grupo, alumnose: alumnosWithNames };
      });

      const updatedGrupos = await Promise.all(updatedGruposPromises);

      const gruposConNombres = await Promise.all(updatedGrupos);

      setGrupos(gruposConNombres);
    } catch (error) {
      console.error("Error fetching :", error);
    } finally {
      setLoading(false);
    }
  };
  const fetchCuadernoCautelar = () => {
    axios

      //tipo cuaderno
      .get(`${window.BACKEND_URL}alumno/tipocuaderno`, {
        headers: {
          Authorization: `${sessionStorage.getItem("token")}`,
        },
      })
      .then((response) => {
        setCuaderno(response.data);
      })
      .catch((error) => {
        console.error("Error fetching Distrito Judicial:", error);
      });
  };
  useEffect(() => {
    fetchData();
    fetchCuadernoCautelar();
  }, [id, data_id]);

  const handleOpenModal = async (expeId) => {
    try {
      const response = await axios.get(
        //expediente
        `${window.BACKEND_URL}alumno/expediente/get/${expeId}`,
        {
          params: { _id: id },
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );
      setModalIdentifier(expeId);
      setModalIsOpen(true);

      const response2 = await axios.get(
        //curso grupo
        `${window.BACKEND_URL}alumno/grupocurso/get/${response.data[0].grupo_id}`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
        }
      );

      let alumnoIdsSet = new Set();
      response2.data.forEach((data) => {
        data.alumnose.forEach((alumno) => {
          alumnoIdsSet.add(alumno.alumno_id);
        });
      });
      let alumnoIds = Array.from(alumnoIdsSet);
      //user
      const users = await axios.get(
        `${window.BACKEND_URL}alumno/users/get/ids`,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
          },
          params: {
            userIds: alumnoIds,
          },
        }
      );

      const updatedGrupos = response2.data.map(async (grupo) => {
        const alumnosWithNamesPromises = grupo.alumnose.map(async (alumno) => {
          const usuario = users.data.find(
            (data) => data._id === alumno.alumno_id
          );

          if (usuario) {
            alumno.nombre = usuario.name;
          } else {
            console.error(
              "Usuario no encontrado para el ID:",
              alumno.alumno_id
            );
          }
          return alumno;
        });

        const alumnosWithNames = await Promise.all(alumnosWithNamesPromises);

        return { ...grupo, alumnose: alumnosWithNames };
      });

      const datagrupo = await Promise.all(updatedGrupos);

      Object.assign(response.data[0], { datagrupo });

      console.log(response.data);
      setExpediente(response.data);

      const partes = response.data[0].nexpediente.split("-");

      setnumexpe(partes);

      // Crear una copia del número de expediente
      const nuevoNexpediente = partes.slice();

      // Modificar la parte específica que deseas cambiar
      nuevoNexpediente[2] = response.data[0].cuaderno.length;
    } catch (error) {
      console.error(error);
    }
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Obtener el formulario utilizando su ID
    const form = document.getElementById("myForm");
    // Verificar si el formulario se encontró
    if (!form) {
      console.error("No se encontró el formulario con el ID proporcionado");
      return;
    }

    // Crear un objeto para almacenar los datos del formulario
    const dataform = {};

    // Recorrer todos los elementos del formulario
    for (let element of form.elements) {
      // Verificar si el elemento tiene un nombre y no es un botón
      if (element.name && element.type !== "submit") {
        // Agregar el valor del elemento al objeto formData
        dataform[element.name] = element.value;
      }
    }

    let expedi_id;

    if (cuadernoSeleccionado) {
      if (dataform.cuaderno_id === cuadernoSeleccionado._id) {
        dataform.fech = expediente[0].created;
        dataform.nombre = cuadernoSeleccionado.nombre;
        expedi_id = dataform.expe_id;
        cuadernoSeleccionado.fech = expediente[0].created;

        try {
          const nuevoNexpediente = numexpe.slice();

          nuevoNexpediente[2] = expediente[0].cuaderno.length + 1;

          const nuevoNumeroExpediente = nuevoNexpediente.join("-");
          const response23 = await axios.post(
            `${window.BACKEND_URL}alumno/expe_cuaderno/data/post`,
            {
              cuaderno: true,
              cuaderno_id: cuadernoSeleccionado.id,
              expe_raiz: expedi_id,
              curso_id: expediente[0].curso_id,
              grupo_id: expediente[0].grupo_id,
              nexpediente: nuevoNumeroExpediente,
              alumnos: expediente[0].datagrupo[0].alumnose,

              pretencion: expediente[0].datagrupo[0].pretencion,
              fecha: expediente[0].created,
              docente_id: expediente[0].datacurso[0].docente_id,
              demanda: expediente[0]?.demanda || null,
              avanze: expediente[0]?.avanze || null,
            },
            {
              headers: {
                Authorization: sessionStorage.getItem("token"),
              },
            }
          );

          const data_id_cuaderno = response23.data.userId;

          const dataformfinal = {};
          dataformfinal.cuaderno_id = data_id_cuaderno;
          dataformfinal.fech = dataform.fech;
          dataformfinal.nombre = dataform.nombre;

          dataformfinal.nexpediente = nuevoNumeroExpediente;

          try {
            await axios.put(
              `${window.BACKEND_URL}alumno/expediente/cuaderno/put/${expedi_id}`,
              { cuaderno: dataformfinal },
              {
                headers: {
                  Authorization: `${sessionStorage.getItem("token")}`,
                },
              }
            );
            // Mostrar un mensaje de éxito al usuario
            Swal.fire({
              icon: "success",
              title: "Guardado",
              text: "¡Se guardó correctamente!",
            });
          } catch (error) {
            console.error(error);
          }
        } catch (error) {
          console.error(error);
        }
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "No escogio ningun cuaderno.",
      });
    }
    handleCloseModal();
  };

  // Exportar la función personalizada para enviar el dato desde el primer archivo

  return (
    <>
      {loading ? (
        <div className="text-center">
          <i className="fa-solid fa-spinner fa-spin icon fs-1 bg-stemis"></i>
          <p>Cargando Casos...</p>
        </div>
      ) : (
        <>
          {grupos.length === 0 ? (
            <div className="d-flex align-items-center justify-content-center h-50">
              <div className="text-center">
                <h1 className="display-1 fw-bold">:C</h1>
                <p className="fs-3">
                  <span className="text-danger">Opps!</span> Todavia no se le
                  asigno ningun grupo.
                </p>
                <p className="lead">---</p>
              </div>
            </div>
          ) : (
            grupos.map((datos, index) => (
              <section
                key={index}
                className=" bg-body-secondary  mb-3  rounded-5 shadow"
              >
                <div className="row text-center">
                  <div className=" ">
                    <h2 className="m-2">
                      <b>Caso : {datos.datapretension[0].nombre}</b>
                    </h2>
                    <hr className="mx-2" />
                  </div>
                </div>
                <div className="container  ">
                  <div className="row justify-content-center px-0">
                    <div className="col-md-4 col-12  px-1 m-0">
                      <div className="px-2 flex-fill mx-2 rounded-4">
                        <h6 className="text-center pb-2 pt-2">
                          <b> Información del caso </b>
                        </h6>

                        <div className="container-fluid bg-dark-subtle shadow rounded-2">
                          <div className="row position-relative m-0 g-0 pt-2">
                            <div className="col-5  p-0 m-0">
                              <b>Rol</b>
                            </div>
                            <div className="col-7 p-0 m-0">
                              {datos.alumnose.map((alumno) =>
                                alumno?.alumno_id === data_id
                                  ? alumno.rol_id
                                  : null
                              )}
                            </div>
                          </div>
                          <hr className=" m-0 py-1 " />
                          <div className="row m-0 p-0 ">
                            <div className="col-5  p-0 m-0">
                              <b>Materia</b>
                            </div>
                            <div className="col-7 p-0 m-0">{datos.materia}</div>
                          </div>
                          <hr className=" m-0 py-1" />
                          <div className="row m-0 p-0   ">
                            <div className="col-5  p-0 m-0">
                              <b>Expediente</b>
                            </div>
                            <div className="col-7 p-0 m-0">
                              {datos.expediente_info[0].nexpediente}
                            </div>
                          </div>
                          <hr className=" m-0 py-1 " />
                          <div className="row m-0 p-0 ">
                            <div className="col-5  p-0 m-0">
                              <b>Integrantes</b>
                            </div>
                            <div className="col-7 p-0 m-0">
                              {datos.alumnose.length}
                            </div>
                          </div>
                        </div>

                        <div className="row justify-content-center mx-2 pt-2">
                          <Link
                            to={`/expediente/${datos.expe_id}`}
                            style={style1}
                            className="btn m-2"
                          >
                            Ver Expediente
                          </Link>
                        </div>
                      </div>
                    </div>

                    <React.Fragment key={datos._id}>
                      <div className="col-md-5  col-12  px-0">
                        <div className="p-2 flex-fill rounded-4 mx-2">
                          <h6 className="text-center pb-2 ">
                            <b>Integrantes</b>
                          </h6>
                          <div className="row justify-content-center pt-0 mt-0">
                            <div
                              className="col text-center align-items-center"
                              id="integrat-cursos-scroll"
                            >
                              {datos.alumnose.map((alumno) => (
                                <div
                                  key={alumno?.alumno_id}
                                  className="row align-items-center justify-content-center px-0"
                                >
                                  <div className="col-3  px-0 mx-0 text-end-0">
                                    <img
                                      src={`${window.BACKEND_URL}uploads/${
                                        alumno?.photo
                                          ? alumno?.photo
                                          : "imagen.jpg"
                                      }`}
                                      alt=""
                                      className="rounded-circle px-0 mx-0 "
                                      style={{
                                        minWidth: "2.5rem",
                                        minHeight: "2.5rem",
                                        objectFit: "cover",
                                        maxWidth: "2.5rem",
                                        maxHeight: "2.5rem",
                                      }}
                                    />
                                  </div>
                                  <div className="col-9 pt-1">
                                    <div className="row align-items-center">
                                      <div className="row m-0 p-0">
                                        <p className="text-start position-relative m-0 p-0">
                                          {alumno.nombre}
                                        </p>
                                      </div>

                                      <div className="row p-0 pb-2 m-0">
                                        <h4 className="text-start position-relative p-0 m-0">
                                          {alumno.rol_id}
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-3 col-12   px-0">
                        <div className=" flex-fill rounded-4 ">
                          {datos.alumnose.map((alumno) => {
                            // Verificar si el ID del alumno coincide con data_id
                            if (alumno?.alumno_id === data_id) {
                              // Dependiendo del rol del alumno, mostrar los botones apropiados
                              switch (alumno.rol_id) {
                                case "Demandante":
                                  return (
                                    <React.Fragment
                                      key={`${alumno?.alumno_id}-demandante`}
                                    >
                                      <h6 className="text-center p-2">
                                        <b>Presenta tu</b>
                                      </h6>
                                      <div className="row justify-content-center">
                                        <Link
                                          to={`/${datos.expediente_info[0]._id}/demanda`}
                                          className={`btn m-2 w-75 d-flex justify-content-between ${
                                            datos.expediente_info[0]?.typedema
                                              ? "disabled"
                                              : ""
                                          }`}
                                          style={style1}
                                        >
                                          <span style={{ margin: "auto" }}>
                                            Demanda
                                          </span>
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                      </div>
                                      <div className="row justify-content-center">
                                        <Link
                                          to={`/${datos.expe_id}/escritos`}
                                          className={`btn m-2 w-75 d-flex justify-content-between align-items-center ${
                                            datos.expediente_info[0]?.typedema
                                              ? ""
                                              : "disabled"
                                          }`}
                                          style={style1}
                                        >
                                          <span style={{ margin: "auto" }}>
                                            Escrito
                                          </span>
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                      </div>
                                      <h6 className="text-center p-2">
                                        <b>Crea tu</b>
                                      </h6>
                                      <div className="row justify-content-center">
                                        <button
                                          className={`btn m-2 w-75 d-flex justify-content-between align-items-center ${
                                            datos.expediente_info[0]?.typedema
                                              ? ""
                                              : "disabled"
                                          }`}
                                          style={style1}
                                          onClick={() => {
                                            if (datos.expe_id) {
                                              // Verificar si cursogrupo.expe_id tiene un valor
                                              handleOpenModal(datos.expe_id);
                                            } else {
                                              console.error(
                                                "El ID del expediente no está definido."
                                              );
                                            }
                                          }}
                                        >
                                          <span style={{ margin: "auto" }}>
                                            Cuaderno
                                          </span>
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </button>
                                      </div>
                                    </React.Fragment>
                                  );
                                case "Demandado":
                                  // Mostrar un solo botón para el demandado
                                  return (
                                    <React.Fragment
                                      key={`${alumno?.alumno_id}-Demandado`}
                                    >
                                      <h6 className="text-center p-2">
                                        <b>Presenta tu</b>
                                      </h6>
                                      <div className="row justify-content-center">
                                        <Link
                                          to={`/${datos.expe_id}/escritos`}
                                          className={`btn m-2 w-75 d-flex justify-content-between align-items-center ${
                                            datos.expediente_info[0]?.typedema
                                              ? ""
                                              : "disabled"
                                          }`}
                                          style={{
                                            width: "250px",
                                            color: "#e4e4e4",
                                            backgroundColor: "#009688",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span style={{ margin: "auto" }}>
                                            Escrito
                                          </span>
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                      </div>
                                      <h6 className="text-center p-2">
                                        <b>Crea tu cuaderno de </b>
                                      </h6>
                                      <div className="row justify-content-center">
                                        <button
                                          className={`btn m-2 w-75 d-flex justify-content-between align-items-center ${
                                            datos.expediente_info[0]?.typedema
                                              ? ""
                                              : "disabled"
                                          }`}
                                          style={style1}
                                          onClick={() => {
                                            if (datos.expe_id) {
                                              // Verificar si cursogrupo.expe_id tiene un valor
                                              handleOpenModal(datos.expe_id);
                                            } else {
                                              console.error(
                                                "El ID del expediente no está definido."
                                              );
                                            }
                                          }}
                                        >
                                          <span style={{ margin: "auto" }}>
                                            Excepcion y/o defensa previa
                                          </span>
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </button>
                                      </div>
                                    </React.Fragment>
                                  );
                                case "Especialista":
                                case "Juez":
                                  // Mostrar un solo botón para el demandado
                                  return (
                                    <React.Fragment
                                      key={`${alumno?.alumno_id}-Juez`}
                                    >
                                      <h6 className="text-center p-2">
                                        <b>Registra tu</b>
                                      </h6>
                                      <div className="row justify-content-center">
                                        <Link
                                          to={`/${datos._id}/avance`}
                                          style={{
                                            width: "250px",
                                            color: "#e4e4e4",
                                            backgroundColor: "#009688",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                          }}
                                          className={`btn m-2 w-75 d-flex justify-content-between align-items-center ${
                                            datos.expediente_info[0]?.typedema
                                              ? ""
                                              : "disabled"
                                          }`}
                                        >
                                          <span style={{ margin: "auto" }}>
                                            Avance del proceso
                                          </span>
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                      </div>

                                      <h6 className="text-center p-2">
                                        <b>Genera </b>
                                      </h6>
                                      <div className="row justify-content-center">
                                        <Link
                                          to={`/${datos.expe_id}/notifi`}
                                          className={`btn m-2 w-75 d-flex justify-content-between align-items-center ${
                                            datos.expediente_info[0]?.typedema
                                              ? ""
                                              : "disabled"
                                          }`}
                                          style={{
                                            width: "250px",
                                            color: "#e4e4e4",
                                            backgroundColor: "#009688",
                                          }}
                                        >
                                          <span style={{ margin: "auto" }}>
                                            Notificaciones
                                          </span>
                                          <i className="fa-solid fa-arrow-right"></i>
                                        </Link>
                                      </div>
                                    </React.Fragment>
                                  );
                                default:
                                  // En caso de otros roles, no mostrar ningún botón
                                  return null;
                              }
                            }
                            return null; // No mostrar nada si no se encuentra el alumno con data_id
                          })}
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                </div>{" "}
                <Modal
                  isOpen={modalIsOpen && modalIdentifier === datos.expe_id}
                  style={customStyles}
                  onRequestClose={handleCloseModal}
                >
                  <form id="myForm">
                    <div className="row pb-4">
                      <input
                        type="hidden"
                        name="expe_id"
                        value={datos.expe_id}
                      />
                      <div className="col-md-4 mt-2">
                        <label htmlFor="inputEmail3">
                          <p>
                            Tipo de cuaderno
                            <text className="text-danger">*</text>
                          </p>
                        </label>
                      </div>{" "}
                      <div className="col-md-8 p-0 mt-2">
                        <select
                          className="form-control"
                          id="cuaderno_id"
                          name="cuaderno_id"
                          required
                          value={cuadernoSeleccionado._id}
                          onChange={(e) => {
                            const dataselect = cuadernos.find(
                              (distrito) => distrito._id === e.target.value
                            );
                            setCuadernoSeleccionado(dataselect);
                          }}
                        >
                          <option value="">Seleccionar</option>
                          {cuadernos.map((data) => (
                            <option key={data._id} value={data._id}>
                              {data.nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className=" d-flex justify-content-between">
                      <button
                        className="btn px-2 "
                        type="submit"
                        style={{
                          backgroundColor: "#ffffff",
                          color: "#868686",
                          borderColor: "#868686",
                          textAlign: "right",
                          fontWeight: "500",
                        }}
                        onClick={handleSubmit}
                      >
                        Confirmar
                      </button>
                      <button
                        id="myButton"
                        className="btn btn-primary px-4 "
                        onClick={handleCloseModal}
                      >
                        Cancelar
                      </button>
                    </div>
                  </form>
                </Modal>
              </section>
            ))
          )}
        </>
      )}
    </>
  );
}

export default DatosCurso;
